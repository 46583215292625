<template>
    <VeeField name="title" rules="required|salutation" v-model="useCartFormData().value.invoiceAddress.title" v-slot="{field,meta}">
        <select v-bind="field"  :class="meta.touched ? (meta.valid) ? 'valid' : 'invalid' : ''" class="inp sm appearance-none" @change="change($event.target.value)">
            <option value="UNKNOWN" selected disabled>Anrede*</option>
            <option value="MR">Herr</option>
            <option value="MS">Frau</option>
            <option value="DIVERSE">Divers</option>
            <option value="COMPANY">Firma</option>
            <option value="FAMILY">Familie</option>
        </select>
    </VeeField>
    <VeeErrorMessage name="salutation" />
</template>
<script setup lang="ts">
  const emit = defineEmits(["changeSalutations"]);
  function change(value) {
    if(value != 'COMPANY') {
      useCartFormData().value.invoiceAddress.company = ""
    }
    emit("changeSalutations", value);
  }
  if(useCartFormData().value.invoiceAddress?.title) change(useCartFormData().value.invoiceAddress.title)
</script>
