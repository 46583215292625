<template>
  <div class="flex flex-col gap-4 theme-default text-sm p-4">
    <h2 class="py-2">AGB</h2>
    <h3>
      I. Allgemeine Geschäftsbedingungen (AGB) der expert e-Commerce GmbH, Langenhagen
    </h3>
    <h4>1. Geltungsbereich</h4>
    <p>
      1.1 Vertragspartner im Rahmen der AGB sind die expert e-Commerce GmbH, Bayernstrasse
      4, 30855 Langenhagen, (nachfolgend "wir" oder „Verkäufer“ genannt) und der Kunde
      (nachfolgend auch "Sie" genannt). Diese AGB gelten für die Geschäftsbeziehungen
      zwischen uns und unseren Kunden.<br />&nbsp;<br />1.2 Lieferungen, Leistungen und
      Angebote des Verkäufers erfolgen ausschließlich auf der Grundlage dieser AGB in
      ihrer zum Zeitpunkt der Bestellung gültigen Fassung.<br />&nbsp;<br />1.3 Diese AGB
      gelten nicht für Mobilfunkverträge. Vertragspartner des Kunden beim Abschluss von
      Mobilfunkverträgen ist nicht die expert e-Commerce GmbH, sondern der jeweilige
      Mobilfunknetzbetreiber (z.B. Telekom oder Vodafone). Der den Kunden betreuende
      expert-Fachmarkt vor Ort ist dann lediglich der Vermittler solcher Verträge. Kauft
      der Kunde bei der expert e-Commerce GmbH ein vergünstigtes Smartphone in Verbindung
      mit dem Abschluss eines Mobilfunkvertrages, den der expert Fachmarkt vor Ort für den
      Kunden vermittelt, gelten diese AGB deshalb nur für den Kaufvertrag über das
      Smartphone.
    </p>
    <h4>2. Vertagsschluss</h4>
    <p>
      2.1 Die von uns im Internet zum Abruf bereit gehaltenen Waren- und
      Preisbeschreibungen stellen kein Angebot im Sinne der §§ 145 ff. BGB, sondern eine
      unverbindliche Aufforderung an den Kunden, Waren und/oder Dienstleistungen beim
      Verkäufer zu bestellen, dar.<br />&nbsp;<br />2.2 Der Kunde kann die Ware mittels
      Online-Formular im Internet oder per Telefon bestellen. Im Verlauf des
      Bestellprozesses mittels Online-Formular wird dem Kunden bei der Auswahl der
      Lieferart die Möglichkeit gegeben, die Lieferart durch Anklicken des Feldes „ändern“
      zu ändern. Vor Abgabe seiner Bestellung wird ihm eine Zusammenfassung des Inhalts
      seiner Bestellung angezeigt, damit er die Richtigkeit der Angaben nochmals
      kontrollieren kann. Die Angaben zu der Rechnungsadresse, der Lieferadresse, der
      Zahlungsart und den Kontaktdaten können hier durch Anklicken des jeweils angegebenen
      Feldes „ändern“ noch geändert werden.<br />&nbsp;<br />2.3 Im Fall der Bestellung
      per Internet leitet der Kunde nach Eingabe der für die Vertragsabwicklung
      erforderlichen Informationen und Auswahl der Lieferart sowie der Zahlungsart den
      Bestellvorgang durch einen Klick auf einen Link mit der Bezeichnung »Jetzt kaufen«
      oder »PayPal Jetzt kaufen« ein. Damit gibt der Kunde ein bindendes Angebot ab.<br />&nbsp;<br />2.4
      Dem Kunden wird unverzüglich nach Abgabe des Angebots eine automatisch generierte
      E-Mail zugesandt, die den Eingang seiner Bestellung auf unserem Server bestätigt
      (Bestellbestätigung). Diese E-Mail stellt noch keine Annahme des Vertragsangebots
      dar, sondern informiert lediglich über den Eingang der Bestellung.<br />&nbsp;<br />2.5
      Wir sind berechtigt, das Angebot innerhalb von 5 Tagen anzunehmen. Nach fruchtlosem
      Fristablauf gilt das Angebot als abgelehnt. Es ist nicht erforderlich, dass wir die
      Annahme des Angebots ausdrücklich erklären.<br />&nbsp;<br />2.6 Ein Kaufvertrag
      kommt dann zustande, wenn wir entweder das bestellte Produkt oder eine separate
      E-Mail über die Annahme Ihres Angebots an Sie versenden. Über Produkte, die nicht in
      der Versandbestätigung aufgeführt sind, kommt kein Kaufvertrag zustande.<br />&nbsp;<br />2.7
      Diese AGB stehen dem Kunden zum Abruf und zur Speicherung auf unserer Webseite
      bereit.<br />&nbsp;<br />2.8 Den Text seiner Bestellung einschließlich der geltenden
      AGB bestätigen wir dem Kunden per E-Mail.<br />&nbsp;<br />2.9 Der Vertragsschluss
      kann nur in deutscher Sprache erfolgen.<br />&nbsp;<br />2.10 Wir verkaufen
      ausschließlich an Endverbraucher und nicht an Zwischenhändler.
    </p>
    <h4>3. Preise</h4>
    <p>
      3.1 Die angegebenen Preise enthalten jeweils die gesetzliche Umsatzsteuer und
      sonstige Preisbestandteile und gelten zuzüglich etwaiger Versandkosten.<br />&nbsp;<br />3.2
      Für Versandkosten werden die Kosten gesondert berechnet. Zu den Versandkosten zählen
      Kosten für Porto, Verpackung und Logistik. Die Versandkosten sind produktabhängig
      und werden auf der Webseite im Warenkorb angezeigt. Sie beinhalten die gesetzliche
      Umsatzsteuer. Zu den Versandkosten für die Rücksendung der Ware verweisen wir auf
      die nachstehende Widerrufsbelehrung (Ziffer 6).<br />&nbsp;<br />3.3 Sämtliche
      Forderungen des Verkäufers sind vorbehaltlich einer anderweitigen schriftlichen
      Vereinbarung sofort und ohne Abzug fällig und zahlbar.<br />&nbsp;<br />3.4&nbsp;Wir
      liefern nur innerhalb Deutschlands. Rechnungs- und Lieferadresse müssen deshalb in
      Deutschland liegen.<br />&nbsp;<br />3.5 Der Kunde ist zur Aufrechnung nur
      berechtigt, sofern und soweit seine Gegenansprüche rechtskräftig festgestellt,
      unbestritten oder von uns anerkannt sind.
    </p>
    <h4>4. Lieferbedingungen</h4>
    <div>
      <p>
        4.1&nbsp;Die Lieferung erfolgt in transportsicherer Verpackung an die gemäß Ziffer
        3.4 anzugebende Lieferadresse.&nbsp;<br />&nbsp;<br />4.2 Die Lieferzeit beträgt,
        sofern nicht beim Angebot anders angegeben, 3 bis 7 Tage.
      </p>
      <p>
        4.3 Für Speditionsartikel gilt folgendes:&nbsp;Sendungen, bei denen die
        Abholadresse oder die Zustelladresse ungeeignet oder nur unter unverhältnismäßigen
        Schwierigkeiten erreichbar sind, sind ausgeschlossen. Dazu zählen
        Postfachanschriften, Packstationen, weitläufige Firmengelände, Kleingartenanlagen,
        Kasernengelände, weitläufige Behördengebäude sowie Großkundenpostleitzahlen.
        <br />Des Weiteren muss ab der 5. Etage in einem Gebäude ein funktionsfähiger und
        nutzbarer Aufzug vorhanden sein.<br />&nbsp;<br />4.4 Falls nicht alle bestellten
        Produkte vorrätig sind, dürfen wir Teillieferungen auf unsere Kosten vornehmen,
        soweit dies für Sie zumutbar ist.<br />&nbsp;<br />4.5 Wenn wir die Ware trotz
        Auslieferversuch und/oder Bereitstellung zur Abholung nebst Information an Sie
        nicht ausliefern können, dürfen wir vom Vertrag zurücktreten. Gegebenenfalls
        geleistete Zahlungen werden Ihnen unverzüglich erstattet.<br />&nbsp;<br />4.6
        Sollte das bestellte Produkt nicht verfügbar sein, weil wir mit diesem Produkt von
        unseren Lieferanten ohne eigenes Verschulden nicht beliefert werden, dürfen wir
        vom Vertrag zurücktreten. In diesem Fall werden wir Sie unverzüglich informieren
        und Ihnen gegebenenfalls die Lieferung eines vergleichbaren Produktes vorschlagen.
        Wenn kein vergleichbares Produkt verfügbar ist oder Sie keine Lieferung eines
        vergleichbaren Produktes wünschen, werden wir Ihnen gegebenenfalls bereits
        erbrachte Gegenleistungen unverzüglich erstatten.<br />&nbsp;<br />4.7 Erhalten
        Sie die Ware mit offensichtlichen Transportschäden, so reklamieren Sie solche
        Schäden bitte sofort bei dem Zusteller und benachrichtigen Sie uns bitte
        schnellstmöglich per E-Mail an&nbsp;<a
          href="mailto:kundenservice@expert-commerce.de"
          target="_self"
          >kundenservice@expert-commerce.de</a
        >.<br />&nbsp;<br />4.8 Die Versäumung einer Reklamation oder Kontaktaufnahme hat
        für Ihre gesetzlichen Gewährleistungsansprüche keinerlei Konsequenzen. Sie helfen
        uns aber, unsere eigenen Ansprüche gegenüber dem Frachtführer oder der
        Transportversicherung geltend machen zu können.
      </p>
    </div>
    <h4>5. Zahlungsbedingungen</h4>
    <div>
      <p>
        Wir bieten folgende Zahlarten an: per <strong>Sofortüberweisung</strong> (Sofort),
        per <strong>PayPal</strong>, per <strong>Kreditkarte</strong> (VISA, Mastercard,
        American Express)&nbsp;oder per <strong>Rechnung</strong> (Klarna).
      </p>
      <p>
        Die Zahlungsoptionen Rechnung und Sofortüberweisung bieten wir Ihnen in
        Zusammenarbeit mit der&nbsp;Klarna Bank AB (publ), Sveavägen 46, 111 34 Stockholm,
        Schweden an. Die Zahlung erfolgt jeweils an Klarna.
      </p>
      <ul>
        <li>
          <strong>Rechnung</strong>: Die Zahlungsfrist beträgt [14] Tage ab Versand der
          Ware. Die vollständigen Rechnungsbedingungen für die Länder in denen diese
          Zahlart verfügbar ist finden Sie
          <a
            href="https://cdn.klarna.com/1.0/shared/content/legal/terms/EID/de_de/invoice?fee%3D0"
            >hier</a
          >.
        </li>
        <li>
          <strong>Sofortüberweisung</strong>: Verfügbar in Deutschland.&nbsp;Die Belastung
          Ihres Kontos erfolgt unmittelbar nach Abgabe der Bestellung.
        </li>
      </ul>
      <p class="tinyMCECSS">&nbsp;</p>
      <p class="tinyMCECSS">
        Die Nutzung der Zahlungsart <strong>Rechnung&nbsp;</strong>setzt eine
        positive&nbsp;Bonitätsprüfung&nbsp;&nbsp;voraus. Insofern leiten wir Ihre Daten im
        Rahmen der Kaufanbahnung und Abwicklung des Kaufvertrages an Klarna zum Zwecke der
        Adress- und Bonitätsprüfung weiter. Bitte haben Sie Verständnis dafür, dass wir
        Ihnen nur diejenigen Zahlarten anbieten können, die aufgrund der Ergebnisse der
        Bonitätsprüfung zulässig sind. Weitere Informationen und Klarnas
        Nutzungsbedingungen finden Sie&nbsp;<a
          href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/de_de/user"
          >hier</a
        >. Allgemeine Informationen zu Klarna erhalten Sie&nbsp;<a
          href="https://www.klarna.com/de/"
          >hier</a
        >. Ihre Personenangaben werden von Klarna in Übereinstimmung mit den geltenden
        Datenschutzbestimmungen und entsprechend den Angaben in&nbsp;<a
          href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/de_de/privacy"
          >Klarnas Datenschutzbestimmungen</a
        >&nbsp;behandelt.
      </p>
      <p>
        Sollten Fragen zu Ihrer Bestellung aufkommen (Kauf auf Rechnung), klicken
        Sie&nbsp;<a href="https://www.klarna.com/de/kundenservice/">hier</a>.
      </p>
    </div>
    <h4>6. Widerrufsbelehrung</h4>
    <div>
      <table
        style="width: 100%"
        cellspacing="0"
        cellpadding="2"
      >
        <tbody>
          <tr>
            <td style="vertical-align: top" valign="top">
              <p>
                6.1 Widerrufsrecht<br />Sie haben das Recht, binnen 14 Tagen ohne Angabe
                von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt 14
                Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der
                nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat. Im
                Falle eines Vertrages über mehrere Waren, die Sie im Rahmen einer
                einheitlichen Bestellung bestellt haben und die getrennt geliefert werden,
                beginnt die Frist an dem Tag, an dem Sie oder ein von Ihnen benannter
                Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen
                haben bzw. hat. Im Falle eines Vertrages über die Lieferung einer Ware in
                mehreren Teilsendungen oder Stücken beginnt die Frist an dem Tag, an dem
                Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist,
                die letzte Teilsendung oder das letzte Stück in Besitz genommen haben bzw.
                hat.<br />&nbsp;<br />Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
                mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
                Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
                widerrufen, informieren. Sie können dafür das beigefügte
                Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben
                ist.<br />&nbsp;<br />Der Widerruf ist zu richten an:<br />&nbsp;<br />expert
                e-Commerce GmbH, Bayernstraße 4, 30855 Langenhagen<br />oder per Telefax
                an: 0511/7808- 420
              </p>
              oder per Telefon an: 0203 - 80481842
              <p>
                oder per E-Mail an:&nbsp;<a
                  href="mailto:kundenservice@expert-commerce.de"
                  target="_self"
                  >kundenservice@expert-commerce.de</a
                >
              </p>
              <p>
                &nbsp;<br />Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
                Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                Widerrufsfrist absenden.
              </p>
              <p>&nbsp;</p>
              <p><strong>Widerrufs-Formular</strong></p>
              <p>
                &nbsp;<br />(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte
                dieses Formular aus und senden Sie es zurück.)
              </p>
              <p>
                &nbsp;<br />— An&nbsp;<br />&nbsp;&nbsp;&nbsp; expert e-Commerce GmbH,
                Bayernstraße 4, 30855 Langenhagen,&nbsp;<br />&nbsp;&nbsp;&nbsp; Telefax
                0511/7808-420,<br />&nbsp;&nbsp;&nbsp; Mail:&nbsp;<a
                  href="mailto:kundenservice@expert-commerce.de"
                  target="_self"
                  >kundenservice@expert-commerce.de</a
                >
              </p>
              <p>
                &nbsp;&nbsp;<br />— Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
                abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die
                Erbringung der folgenden Dienstleistung (*)
              </p>
              <p>
                &nbsp;<br />— Bestellt am (*)/erhalten am (*)&nbsp;<br />— Name des/der
                Verbraucher(s)&nbsp;<br />— Anschrift des/der Verbraucher(s)
              </p>
              <p>
                &nbsp;<br />— Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf
                Papier)&nbsp;<br />— Datum
              </p>
              <div class="aligncenter">&nbsp;</div>
              <p>
                6.2 Folgen des Widerrufs&nbsp;<br />Wenn Sie diesen Vertrag widerrufen,
                haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
                einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
                sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von
                uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich
                und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die
                Mitteilung über Ihren Widerruf dieses Vertrages bei uns eingegangen ist.
                Für diese Rückzahlung verwenden wir das selbe Zahlungsmittel, das Sie bei
                der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
                wurde ausdrücklich etwas Anderes vereinbart; in keinem Fall werden Ihnen
                wegen dieser Rückzahlung Entgelte berechnet.<br />&nbsp;<br />Wir können
                die Rückzahlung verweigern, bis wir die Waren wieder zurück erhalten haben
                oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt
                haben, je nachdem, welches der frühere Zeitpunkt ist.<br />&nbsp;<br />Sie
                haben die Waren unverzüglich und in jedem Fall spätestens 14 Tage ab dem
                Tag, an dem Sie uns über den Widerruf dieses Vertrages unterrichten, an
                uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die
                Waren vor Ablauf der Frist von 14 Tagen absenden.<br />&nbsp;<br />Wenn
                die Waren normal mit der Post zurückgesandt werden können, tragen wir die
                Kosten der Rücksendung, wenn Sie für die Rücksendung das Label benutzen,
                das wir Ihnen auf Anforderung zur Verfügung stellen. Wenn Sie das Label
                nicht benutzen, tragen Sie die unmittelbaren Kosten der Rücksendung der
                Waren. Diese Kosten werden auf höchstens etwa 35 Euro geschätzt. Wenn die
                Waren nicht normal mit der Post zurückgesandt werden können (weil Versand
                mit einer Spedition), tragen Sie die unmittelbaren Kosten der Rücksendung
                der Waren. Die Höhe der Rücksendekosten entspricht dabei der Höhe der
                Hinsendekosten, wenn Sie den von uns beauftragten Spediteur auch für die
                Rücksendung beauftragen. Die Höhe der Hinsendekosten wird Ihnen vor
                Abschluss der Bestellung mitgeteilt. Falls Sie einen anderen als den von
                uns beauftragten Spediteur mit der Rücksendung beauftragen, werden die
                Kosten auf höchstens etwa 112 Euro geschätzt.<br />&nbsp;<br />Sie müssen
                für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser
                Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und
                Funktionsweisen der Waren nicht notwendigen Umgang von Ihnen
                zurückzuführen ist.<br />&nbsp;<br />6.3 Ausnahmen vom Widerrufsrecht<br />Ein
                Widerrufsrecht besteht nicht bei Verträgen zur Lieferung von Ton- oder
                Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn
                die Versiegelung nach der Lieferung entfernt wurde. Ein Widerrufsrecht
                besteht ferner nicht bei Verträgen zur Lieferung versiegelter Waren, die
                aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe
                geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt
                wurde.<br />&nbsp;<br />6.4 Widerrufsrecht bei digitalen Inhalten<br />Wenn
                Sie vor der Ausführung des Vertrages ausdrücklich zustimmen, dass mit der
                Ausführung des Vertrages vor Ablauf der Widerrufsfrist begonnen wird,
                verlieren Sie dadurch Ihr Widerrufsrecht.&nbsp;<br />&nbsp;<br /><strong
                  >Ende der Widerrufsbelehrung</strong
                >
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top" valign="top">&nbsp;</td>
          </tr>
          <tr>
            <td style="vertical-align: top" valign="top">&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <p>
        6.5 Jegliche Rücksendung hat ausschließlich in einer transportsicheren Verpackung
        zu erfolgen. Die Verkaufsverpackungen selbst, ohne gesonderte Transportverpackung,
        sind nicht für einen sicheren Versand geeignet.
      </p>
      <p>
        6.6&nbsp; Für Zwecke der Rücksendung können Sie auf verschiedenen Wegen die
        Rücksende-Unterlagen anfordern. Sie haben zum Beispiel die Möglichkeit, uns über
        das
        <a href="https://www.expert.de/Viersen/Footer/Service/Kontaktformular"
          >Kontaktformular
        </a>
        oder speziell über die Seite
        <a href="https://www.expert.de/mein-expert/retoure-suchen"
          >Retouren &amp; Widerruf</a
        >
        zu kontaktieren. Alternativ können Sie die Rücksende-Unterlagen auch bei unserem
        Kundenservice per Telefon unter der Rufnummer 0203 - 80481842 (Mo-Sa 09:00 – 20:00
        Uhr) oder per E-Mail an&nbsp;<a
          href="mailto:kundenservice@expert-commerce.de"
          target="_self"
          >kundenservice@expert-commerce.de</a
        >&nbsp;anfordern. Die Nutzung der Rücksendeunterlagen stellt keine zwingende
        Voraussetzung zur wirksamen Ausübung des Widerrufsrechts dar. Sie können die
        Leistungen, bezüglich derer Sie Ihre Vertragserklärung widerrufen, auch bei Ihrem
        betreuenden expert Fachmarkt (laut Rechnung) zurückgeben.&nbsp;
      </p>
    </div>
    <h4>7. Eigentumsvorbehalt</h4>
    <p>
      7.1 Die gelieferte Ware verbleibt bis zur vollständigen Zahlung des Kaufpreises in
      unserem Eigentum.<br />&nbsp;<br />7.2 Im Fall von Zwangsvollstreckungsmaßnahmen in
      die Kaufsache hat der Kunde uns unverzüglich unter Übergabe der für eine
      Intervention notwendigen Unterlagen zu informieren.
    </p>
    <h4>8. Gewährleistung</h4>
    <p>Es gelten die gesetzlichen Gewährleistungsregelungen.</p>
    <h4>9. Haftung</h4>
    <p>
      9.1 Wir schließen die Haftung für Schäden, die durch einfache Fahrlässigkeit
      verursacht worden sind, aus, sofern diese nicht aus der Verletzung von
      vertragswesentlichen Pflichten resultieren oder Schäden aus der Verletzung des
      Lebens, des Körpers oder der Gesundheit oder Ansprüche nach dem
      Produkthaftungsgesetz betroffen sind.<br />&nbsp;<br />9.2 Gleiches gilt für
      Pflichtverletzungen unserer Erfüllungsgehilfen und gesetzlichen Vertreter.<br />&nbsp;<br />9.3
      Bei der Verletzung wesentlicher Vertragspflichten ist die Haftung in Fällen
      einfacher Fahrlässigkeit auf die Schäden, die in typischer Weise mit dem Vertrag
      verbunden und vorhersehbar sind, beschränkt.
    </p>
    <h4>10. Schlussbestimmungen</h4>
    <p>
      10.1 Falls der Käufer Kaufmann, juristische Person des öffentlichen Rechts oder ein
      öffentlich-rechtliches Sondervermögen ist, ist der Erfüllungsort Langenhagen
      (Hannover).<br />&nbsp;<br />10.2 Falls der Käufer Kaufmann, juristische Person des
      öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen ist, wird für
      etwaige Streitigkeiten aus den Verträgen und damit im Zusammenhang stehenden
      Rechtsbeziehungen für beide Teile das Gericht, das für den Sitz der expert
      e-Commerce GmbH zuständig ist, als Gerichtsstand vereinbart.
    </p>
    <h4>
      11. Alternative Streitbeilegung gemäß Art.14 Abs.1 ODR-VO und § 36 VSBG:
    </h4>
    <p>
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
      bereit, die Sie unter&nbsp;<a
        href="http://ec.europa.eu/consumers/odr/"
        target="_blank"
        rel="noopener noreferrer"
        >http://ec.europa.eu/consumers/odr/</a
      >&nbsp;finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
    </p>
    <h3>II. Hinweise nach dem BatterieG</h3>
    <h4>
      1. Hinweise zur Rückgabe von Batterien gemäß § 18 BattG
    </h4>
    <div>
      <p>
        Gemäß § 18 Batteriegesetz sind wir als Händler verpflichtet, Sie wie folgt zu
        informieren:<br />Sie sind als Endverbraucher zur Rückgabe von Altbatterien
        gesetzlich verpflichtet, wobei sie weder nach Batterietyp noch nach Hersteller
        oder Verkäufer unterscheiden müssen. Batterien dürfen nicht in den Hausmüll
        gegeben werden.&nbsp;<br />Sie können Batterien nach Gebrauch in jedem expert
        Fachmarkt (den für Sie nächstgelegenen expert Fachmarkt finden Sie auf unserer
        Website unter Eingabe Ihrer Postleitzahl) oder an unser Versandlager
        zurückgeben:<br />&nbsp;<br />expert e-Commerce GmbH&nbsp;<br />Bayernstraße 4<br />30855
        Langenhagen<br />&nbsp;<br />Sie können die Batterien auch an den von den
        öffentlich-rechtlichen Entsorgungsträgern dafür eingerichteten Rücknahmestellen
        zurückgeben.<br />Batterien oder Akkus, die Schadstoffe enthalten, sind mit dem
        Symbol einer durchgekreuzten Mülltonne gekennzeichnet. Die durchgestrichene
        Mülltonne bedeutet, dass die Batterie nicht in den Hausmüll gegeben werden darf.
        In der Nähe zum Mülltonnensymbol befindet sich die chemische Bezeichnung des
        Schadstoffes mit folgender Bedeutung:
      </p>
      <p>
        •&nbsp;&nbsp; &nbsp;"Cd": die Batterie enthält mehr als 0.002 Masseprozent
        Cadmium;<br />•&nbsp;&nbsp; &nbsp;"Pb": die Batterie enthält mehr als 0,004
        Masseprozent Blei;<br />•&nbsp;&nbsp; &nbsp;"Hg": die Batterie enthält mehr als
        0,0005 Masseprozent Quecksilber.
      </p>
    </div>
    <h3>III. Hinweise nach dem ElektroG</h3>
    <h4>
      1. Pflicht zur gesonderten Entsorgung von Altgeräten / Trennung von Batterien und
      Akkumulatoren
    </h4>
    <p>
      Besitzer von Altgeräten haben diese einer vom unsortierten Siedlungsabfall
      getrennten Erfassung zuzuführen. Elektro- und Elektronikaltgeräte gehören daher also
      nicht in den Hausmüll!<br />
      &nbsp;<br />Zur Verbesserung der Mülltrennung sieht das ElektroG daher vor, dass
      alle Elektro- und Elektronikgeräte dauerhaft mit folgendem Symbol gekennzeichnet
      werden müssen:
    </p>
    <h4>
      2. Pflicht zur Trennung von Batterien und Altakkumulatoren
    </h4>
    <p>
      Sie haben zudem Altbatterien und Altakkumulatoren, die nicht vom Altgerät
      umschlossen sind, vor der Abgabe an einer Erfassungsstelle von diesem zu trennen.
    </p>
    <h4>3. Rückgabemöglichkeit</h4>
    <div>
      <p data-renderer-start-pos="1780">
        Bei einer Speditionslieferung nehmen wir Ihr Altgerät kostenlos mit und entsorgen
        es fachgerecht. Wählen Sie beim Kauf die Altgerät-Mitnahme als zusätzliche
        Leistung aus und stellen Sie das Altgerät bereit.
      </p>
      <p data-renderer-start-pos="1988">
        Bei einer Paketlieferung ist aufgrund technischer Restriktionen ein 1:1 Umtausch
        im Zuge der Lieferung noch nicht möglich. Melden Sie sich bitte bei der Hotline,
        um ein Versandlabel zu erhalten.
      </p>
      <p data-renderer-start-pos="2184">
        Des Weiteren nehmen wir Ihr Elektrogerät kostenlos an einer unserer Sammelstellen
        entgegen. Bitte klicken Sie auf folgenden Link und geben Sie Ihre PLZ ein. Somit
        können Sie Ihre nächstgelegene Sammelstelle in Erfahrung bringen.
      </p>
      <p data-renderer-start-pos="2414">
        Geht aufgrund einer Verunreinigung des Altgeräts eine Gefahr für die Gesundheit
        und Sicherheit von Menschen aus, so kann die Annahme verweigert werden. Dies gilt
        aber grundsätzlich nicht für beschädigte Altgeräte.
      </p>
    </div>
    <h4>
      4. Eigenverantwortung Löschung personenbezogener Daten
    </h4>
    <div>
      <p>
        Sie sind für die Löschung personenbezogener Daten, die sich gegebenenfalls auf
        Ihrem Altgerät noch befinden, selbst verantwortlich.
      </p>
      <p>&nbsp;</p>
      <p>Falls Sie weitere Fragen haben, helfen wir Ihnen gerne!<br />&nbsp;</p>
      <p>
        <strong>Service-Hotline:</strong><br />Tel. 0203 - 80481842 (erreichbar Mo-Sa von
        09:00 –&nbsp;20:00 Uhr).
      </p>
    </div>
  </div>
</template>
<style scoped>
a {
  @apply text-primary cursor-pointer;
}
p {
  @apply pb-2;
}
h2 {
  @apply text-xl font-bold;
}
h3 {
  @apply text-base text-primary;
}
h4 {
  @apply text-lg text-paragraph font-bold;
}
</style>
