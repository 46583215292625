<template>
  <div class="flex flex-col justify-between gap-2">
    <div class="font-bold">Ihr Passwort muss bestehen aus:</div>
    <div class="flex items-start gap-1">
      <BasicIcon name="circle-info-light" class="pt-[1px] min-w-4 w-4 text-[#a94442]" />
      <span class="align-top">Mindestens 12 Zeichen</span>
    </div>
    <div class="flex items-start gap-1">
      <BasicIcon name="circle-info-light" class="pt-[1px] min-w-4 w-4 text-[#a94442]" />
      <span class="align-top">Groß- und Kleinbuchstaben</span>
    </div>
    <div class="flex items-start gap-1">
      <BasicIcon name="circle-info-light" class="pt-[1px] min-w-4 w-4 text-[#a94442]" />
      <span class="align-top">Mindestens ein Sonderzeichen (#, ?, @ etc.)</span>
    </div>
    <div class="flex items-start gap-1">
      <BasicIcon name="circle-info-light" class="pt-[1px] min-w-4 w-4 text-[#a94442]" />
      <span class="align-top">Vermeiden Sie einfache Muster oder aufeinanderfolgende Zeichen</span>
    </div>
  </div>
</template>
