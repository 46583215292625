<template>
    <template v-if="!isReservation">
        <template v-for="(entry, warrantyId) in vaildation" :key="warrantyId">
            <VeeField :name="'warranty-' + warrantyId" rules="requiredCheckbox" v-model="vaildation[warrantyId].valid"
                v-slot="{ field, meta }" @change="updateFlag()">
                <div :class="classes + ' ' + (meta.touched ? (meta.valid) ? 'valid' : 'invalid' : '')">
                    <label class="flex gap-1 items-baseline">
                        <div><input v-bind="field" :name="'warranty-' + warrantyId" type="checkbox"
                                v-model="vaildation[warrantyId].valid" /></div>
                        <CheckoutVeeFieldWarrantyEntry :item="entry.item" :warrantyId="warrantyId">
                        </CheckoutVeeFieldWarrantyEntry>
                    </label>
                </div>
            </VeeField>
        </template>
    </template>
</template>
<script setup lang="ts">
const props = defineProps({
    isReservation: {
        type: Boolean,
        default: () => {
            return inject("isReservation");
        },
    },
    bins: {
        type: Object
    },
    classes: {
        type: String,
        default: "bg-secondary-light mt-4 p-5 border after:hidden"
    }
})

let validations = {}
if (!props.isReservation) props.bins?.forEach(bin => {
    bin?.itemList?.items?.forEach(item => {
        item?.additionalItems?.items?.forEach(additionalItem => {
            if (additionalItem?.id?.indexOf('OLD_APPLIANCES_REMOVAL') < 0 && additionalItem?.articleId) {
                validations[additionalItem.articleId] = {
                    item: item,
                    valid: useCartFormData().value.insuranceFlag
                }
            }
        });
    });
});
const vaildation = ref(validations);

function updateFlag() {
    let valid = true
    for (let key in vaildation.value) {
        if (!vaildation.value[key].valid) {
            valid = false
            break;
        }
    }
    useCartFormData().value.insuranceFlag = valid
    let formData = useCartFormData()?.value
    updateOrderFlags(useCurrentUserState()?.value?.csrfToken, formData.newsletterFlag, formData.termsFlag, formData.insuranceFlag)
}
</script>
