<template>
    <VeeField name="password" rules="required|password" v-model="useCartFormData().value.registration.login.password" v-slot="{field, meta, errors}">
        <div :class="meta.touched ? (meta.valid) ? 'valid' : 'invalid' : ''" class="relative password">
            <input v-bind="field" class="inp sm" :type="(isText?'text':'password')" placeholder="Passwort*" />
            <BasicIcon @click="isText = !isText" name="magnifying-glass-solid" :class="(isText?'hidden':'')" 
                class="w-4 text-gray-500 cursor-pointer absolute right-2 top-1/2 -translate-y-1/2" />
            <BasicIcon @click="isText = !isText" name="lock-solid" :class="(isText?'':'hidden')" 
                class="w-4 text-gray-500 absolute cursor-pointer right-2 top-1/2 -translate-y-1/2" />
        </div>
        <span>{{ errors[0] }}</span>
    </VeeField>
</template>
<script setup>
    const isText = ref(false)
</script>
<style>
    .password {
        padding-right: 36px;
    }
    .password.valid, 
    .password.invalid {
        padding-right: 52px;
    }
    .password.valid:after,
    .password.invalid:after {
        right: 30px;
    }
</style>